import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { useParams, useNavigate } from "react-router-dom";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

import tw from "twin.macro";
import styled from "styled-components";

import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import {
    getServicesByTitle,
    getServicesByImages,
    getFilesByServiceId,
} from "../Apis/apisall";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
const HeadingRow = tw.div `flex`;
const Heading = tw(SectionHeading)
`text-gray-900 mb-10`;
const Text = styled.div `
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl m-5 relative focus:outline-none`;
const HeadingTitle = tw(
  SectionHeading
)`lg:text-left text-gray-1001 leading-tight`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);
const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full bg-green-1000 rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const PlansContainer = tw.div`flex flex-wrap flex-col lg:flex-row items-center justify-between lg:items-stretch relative`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;
const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-green-1000 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

export default () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const [content, setContent] = useState("");
  const [images, setImages] = useState([{}]);
  const [files, setFiles] = useState([{}]);
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  const navigate = useNavigate();

  const params = useParams();
  let title = params.name;

  useEffect(() => {
    setTimeout(() => {
      getServicesByTitle(title)
        .then((responses) => {
          setContent(responses.data);

          getFilesByServiceId(responses.data.serviceId, 2)
            .then((responsesfiles) => {
              setFiles(responsesfiles.data);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }, 1000);
  }, []);
  function DownloadFiles(files) {
    window.location.href = files;
  }
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <TestimonialsContainer>
            <Testimonials>
              <Testimonial>
                <TextContainer textOnLeft={"textOnLeft"}>
                  <HeadingInfo
                    tw="hidden lg:block"
                    subheading={"subheading"}
                    heading={content.title}
                    description={content.subdes}
                  />
                  <TestimonialTextSlider
                    arrows={false}
                    ref={setTextSliderRef}
                    asNavFor={imageSliderRef}
                    fade={true}
                  ></TestimonialTextSlider>
              
                </TextContainer>
              </Testimonial>
            </Testimonials>
          </TestimonialsContainer>
          <Description>
            <div
              dangerouslySetInnerHTML={{ __html: content.fullDescription }}
            />
          </Description>

          <HeaderContainer>
            <Heading>Downloadable Files</Heading>
            <PlansContainer>
              {files &&
                files.map((card, index) => (
                  <Card key={index}>
                    <CardImage imageSrc={card.images} />
                    <TextInfo>
                      <TitleReviewContainer>
                        <Title>{card.title}</Title>
                        <RatingsInfo></RatingsInfo>
                      </TitleReviewContainer>
                      <SecondaryInfoContainer>
                        <IconWithText>
                          <IconContainer>
                            <LocationIcon />
                          </IconContainer>
                          <Text>{card.typeFiles}</Text>
                        </IconWithText>
                      </SecondaryInfoContainer>
                      <Description>{card.subDes}</Description>
                    </TextInfo>
                    <PrimaryButton
                      onClick={() => DownloadFiles(card.downloadUrls)}
                    >
                      Download Now
                    </PrimaryButton>
                  </Card>
                ))}
            </PlansContainer>
          </HeaderContainer>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);