import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";

import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeaturelogo from "components/features/TwoColWithLogo.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { HashLink } from "react-router-hash-link";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addreservation2 } from "../Apis/apisall";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm text-black`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const userSchema = yup.object().shape({
    // name can not be an empty string so we will use the required function
    name: yup.string().required(),
    email: yup.string().required().email("Invalid Email Address"),
    phone: yup.string().required(),
    whattype: yup.string().required(),
});
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-yellow-900  text-gray-100 w-full py-4 rounded-lg hover:bg-green-1000  flex items-center justify-center `}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
  .bg {
    backgroundcolor: red;
  }
`;
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  button {
    ${tw`bg-white`}
  }
  input,
  select,
  textarea {
    ${tw`w-full text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};
  }
  select {
    ${tw`w-full  text-black`};
  }
  .MuiStack-root {
    ${tw`w-full h-12 border-black text-black`}
    ::placeholder {
      ${tw`text-black`}
    }
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    ${tw`w-full h-12  border-white text-white`}
    ::placeholder {
      ${tw`text-black`}
    }
  }

  .TwoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .Column {
    width: 100%;
  }
  .InputContainer {
    position: relative;
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000;
  }
  .fv-help-block {
    color: red;
  }
`;
const Subheading = tw.span`uppercase tracking-widest font-bold to-black`;
export const PrimaryLink1 = tw(HashLink)
`
text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000
`;
export const NavLinks = tw.div `inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000
`; 
export const PrimaryLink = tw(NavLink)
`
  lg:mx-0
  px-8 py-3 rounded bg-green-1000 text-gray-100
  
  border-b-0
  rounded-full
`;


export default () => {


  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2 style={{ color: "#000", marginBottom: 50 , display:'flex' , justifyContent:'center' }}>
              Submitted
            </h2>
            <h4 style={{ color: "#000", marginBottom: 50 , display:'flex' , justifyContent:'center',textAlign:'center' }}>
            We have received your information. You will receive a confirmation email from us shortly. Please check your inbox as well as your spam and junk folders.
We will be in touch with you as soon as possible.
            </h4>
         </div>
         <div style={{display:'grid',justifyItems:'center'}}>
        <PrimaryLink href="/">Go to the HomePage</PrimaryLink>
         </div>

        </FormContainer>
 
      </Container>
      <Footer />
    </AnimationRevealPage>
    
  );
};