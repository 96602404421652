import React, { useEffect, useState } from "react";

import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { getSlider } from "../../Apis/apisall";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default ({ roundedHeaderButton }) => {
  const [slider, setSlider] = useState("");

  useEffect(() => {
    setTimeout(() => {
      getSlider()
        .then((responses) => {
          setSlider(responses.data);
           
        })
        .catch((error) => {});
    }, 1000);
  }, []);

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              {slider.title}
              <span className="kNVXHI" tw="text-primary-500">
                {" "}
                {slider.subTitle}
              </span>
            </Heading>
            <Paragraph>{slider.description}</Paragraph>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              {slider.images != "" ? (
                <ResponsiveVideoEmbed url={slider.images} tw="w-full" />
              ) : null}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
