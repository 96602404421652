import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link } from "react-scroll";
import Navbar from "./components/Navbar";
import "./app.css";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { HashLink } from "react-router-hash-link";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import UsIconImageSrc from "images/us-flag.png";

import { getContact, getServices } from "../../Apis/apisall";

const Header = tw.header `
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div `inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000
`;

export const PrimaryLink1 = tw(HashLink)
`
text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000
`;
export const PrimaryLink = tw(NavLink)
`
  lg:mx-0
  px-8 py-3 rounded bg-green-1000 text-gray-100
  
  border-b-0
  rounded-full
`;

export const LogoLink = styled(NavLink)
`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];
  const [contact, setContact] = useState("");
  const [service, setService] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      getContact(4)
        .then((responses) => {
          setContact(responses.data);
        })
        .catch((error) => {});

      getServices()
        .then((responses) => {
          setService(responses.data);
        })
        .catch((error) => {});
    }, 1000);
  }, []);
  logoLink = logoLink;

  const defaultLinks = [
    <NavLinks key={1}>
      <PrimaryLink1 smooth spy to="/#Home" style={{ cursor: "pointer" }}>
        Home
      </PrimaryLink1>

      {/* <PrimaryLink1 smooth to="/#services">
        Services
        <ul>
          {service &&
            service.map((items, index) => (
              <li>
                <PrimaryLink1
                  smooth
                  to={
                    items.title == "General Virtual Assistant"
                      ? "/#services1"
                      : items.title == "Administrative Virtual Assistant"
                      ? "/#services2"
                      : items.title == "Real State Virtual Assistant"
                      ? "/#services3"
                      : items.title == "Call Center"
                      ? "/#services4"
                      : "/#services5"
                  }
                >
                  {items.title}
                </PrimaryLink1>
              </li>
            ))}
        </ul> 
      </PrimaryLink1> */}
      <Navbar />
      <PrimaryLink1 smooth to="/#faq">
        FAQs
      </PrimaryLink1>
      <NavLink href="/AboutUs">About Us</NavLink>
      <NavLink href="/Pricing">Pricing</NavLink>

      <PrimaryLink href="/Booking">
        Book a Consultation
      </PrimaryLink>
      <NavLink href="tel:+15086120608" >
           <i>
            <div className="row">
            <img className="column"  src = {UsIconImageSrc}/>
            
            <span>+1 508 612 0608</span>
              </div>
           </i>
                  
        </NavLink>
    </NavLinks>,
  ];
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        <LogoLink href="/">
          <img style={{ width: 300 }} src={contact.logo} alt={contact.title} />
        </LogoLink>
        <PrimaryLink1 smooth spy to="/#Home" style={{ cursor: "pointer" }}>
          Home
        </PrimaryLink1>
        <Navbar />
        <PrimaryLink1 smooth to="/#faq">
          FAQs
        </PrimaryLink1>
        <NavLink href="/AboutUs">About Us</NavLink>
        <NavLink href="/Pricing">Pricing</NavLink>

        <PrimaryLink href="/Booking">Book a Consultation</PrimaryLink>
        <NavLink href="tel:+15086120608" >
           <i>
            <div className="row">
            <img className="column"  src = {UsIconImageSrc}/>
            
            <span>+1 508 612 0608</span>
              </div>
           </i>
                  
        </NavLink>
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
        style={{position: 'fixed' , zIndex: "99" , top: "1px"}}
      >
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {defaultLinks}
          {/* <LogoLink href="/">
          <img style={{ width: 400 }} src={contact.logo} alt={contact.title} />
        </LogoLink>
        <PrimaryLink1 smooth spy to="/#Home" style={{ cursor: "pointer" }}>
          Home
        </PrimaryLink1>
        <Navbar />
        <PrimaryLink1 smooth to="/#faq">
          FAQs
        </PrimaryLink1>
        <NavLink href="/AboutUs">About Us</NavLink>
        <NavLink href="/Pricing">Pricing</NavLink>
        <PrimaryLink href="/Booking">Book a Consultation</PrimaryLink> */}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};