import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { getServices, getContact } from "../../Apis/apisall";

import LogoImage from "images/logo-light.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";

import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";

const Container = tw.div `relative bg-gray-1000 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div `max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div `flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div `px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5 `uppercase text-gray-1001 font-bold`;

const LinkList = tw.ul `mt-6 text-sm font-medium`;
const LinkListItem = tw.li `mt-3`;
const Link = tw.a `border-b-2 border-transparent hocus:border-gray-1001 pb-1 transition duration-300`;

const Divider = tw.div `my-16 border-b-2 border-gray-1001 w-full`;

const ThreeColRow = tw.div `flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div `flex items-center justify-center md:justify-start`;
const LogoImg = tw.img `w-8`;
const LogoText = tw.h5 `ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p `text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div `mt-8 md:mt-0 flex`;
const SocialLink = styled.a `
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  const [contact, setContact] = useState("");
  const [service, setService] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      getContact(4)
        .then((responses) => {
          setContact(responses.data);
        })
        .catch((error) => {});

      getServices()
        .then((responses) => {
          setService(responses.data);
        })
        .catch((error) => {});
    }, 1000);
  }, []);
  return (
    <footer>
      <div id="footer">
        <div className="footer">
          <div className="container">
            <div className="list-footer-box">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-ms-12">
                  <div className="footer-box">
                    <h2 className="title-footer">Main Menu</h2>
                    <ul>
                      <li>
                        <Link href="../../">Home</Link>
                      </li>
                      <li>
                        <Link href="../../AboutUs">About Us</Link>
                      </li>
                      <li>
                        <Link href="../../components/innerPages/PrivacyPolicyPage">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link href="../../components/innerPages/TermsOfServicePage">
                          Terms of Service
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-ms-12">
                  <div className="footer-box">
                    <h2 className="title-footer">Services</h2>
                    <ul>
                      {service &&
                        service.map((item, index) => (
                          <li>
                            <Link
                              href={
                                "/Services/" + item.title.replace(/ /g, "-")
                              }
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-ms-12">
                  <div className="footer-box footer-contact-box">
                    <h2 className="title-footer">Contact Info</h2>
                    <p className="footer-email">
                    <a href="mailto:info@virtuaidhub.com">
                        <i className="fa fa-envelope"></i>
                        info@virtuaidhub.com
                      </a>
                      
                    </p>
                    <p className="footer-email">
                      <a href="mailto:customerservice@virtuaidhub.com">
                        <i className="fa fa-envelope"></i>customerservice@virtuaidhub.com
                      </a>
                    </p>
                    <p className="footer-email">
                      {contact.faceBook != "" && contact.faceBook != null && (
                        <a href={"tel:" + contact.phone} style={{fontSize:"x-large"}}>
                          <i className="fa fa-phone"></i>
                          {contact.phone}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-ms-12">
                  <div className="footer-box">
                    <h2 className="title-footer">Address</h2>
                    <p class="footer-address">
                      <i class="fa fa-map-marker"></i>
                      {contact.address}
                    </p>
                  </div>
                  <div className="social-footer">
                    {contact.faceBook != "" && contact.faceBook != null && (
                      <SocialLink >
                        <i className="fa fa-facebook" style={{ fontSize: '25px' }}></i>
                      </SocialLink>
                    )}
                    {/* {contact.x != "" && contact.x != null && (
                      <SocialLink href={"https://x.com/" + contact.x}>
                        <i className="fa fa-twitter"></i>
                      </SocialLink>
                    )} */}

                    {contact.telegram != "" && contact.telegram != null && (
                      <SocialLink href={"https://t.me/" + contact.telegram}>
                        <i className="fa fa-send" style={{ fontSize: '25px' }}></i>
                      </SocialLink>
                    )}
                    {contact.insta != "" && contact.insta != null && (
                      <SocialLink href={"https://www.instagram.com/" + contact.insta}>
                        <i className="fa fa-instagram" style={{ fontSize: '25px' }}></i>
                      </SocialLink>
                    )}
                    {contact.x != "" && contact.x != null && (
                      <SocialLink href={"https://www.youtube.com/" + contact.x}>
                        <div>
                        <i className="fa fa-youtube" style={{ fontSize: '25px' }}></i>
                        </div>
                      </SocialLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="row">
            <div class="col-md-12">
              <div class="copyright">
                <p>
                  copyright © 2004 virtuaidhub.com allright reserved powered by{" "}
                  <a
                    href="https://www.virtuaidhub.com"
                    title="virtuaidhub"
                    target="_blank"
                  >
                    virtuaidhub
                    <img src="https://admin.virtuaidhub.com/DynamicImages/logo_Bottom.png" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};