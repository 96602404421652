import axios from "axios";
const API_URL = "https://Api.virtuaidhub.com/api";
// const API_URL = "http://localhost:7239/api";

export const SLIDER_URL = `${API_URL}/Slider`;
export const SERVICE_URL = `${API_URL}/Service`;
export const FAQ_URL = `${API_URL}/Faq`;
export const CONTENT_URL = `${API_URL}/Content/GetByKind/`;
export const CONTACT_URL = `${API_URL}/Contact`;
export const RESERVATION_URL = `${API_URL}/Reservation/create/`;

export const SERVICE_URL_TITLE = `${API_URL}/Service/GetByInfo/`;
export const SERVICE_URL_Images = `${API_URL}/ImageSlider/`;
export const PRICING_URL = `${API_URL}/Price/`;
export const PRICINGTABLE_URL = `${API_URL}/PricingTable/`;
export const FILES_URL_SERVICE = `${API_URL}/Files/`;
export const INFOS_URL_SERVICE = `${API_URL}/Info/`;
export const REGISTER_URL = `${API_URL}/Register/create`;
export const LOGIN_URL = `${API_URL}/Register/login`;
export const FORGET_URL = `${API_URL}/Register/forget`;
export const LOGIN_URL1 = `https://wallet.tipoul.com/UserLogin/v1/login`;

export function getSlider() {
    const Urls = SLIDER_URL;

    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getServices() {
    const Urls = SERVICE_URL;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getServicesByTitle(Title) {
    const Urls = SERVICE_URL_TITLE + `${Title}`;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getPriceing() {
    const Urls = PRICING_URL;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getServicesByImages(ServiceId, Kind) {
    const Urls = SERVICE_URL_Images + `${ServiceId}` + "/Kind?Kind=" + `${Kind}`;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getmultiinfoservices(ServiceId, Kind) {
    const Urls = INFOS_URL_SERVICE + `${ServiceId}` + "/Kind";
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getFilesByServiceId(ServiceId) {
    const Urls = FILES_URL_SERVICE + `${ServiceId}`;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getFaqs() {
    const Urls = FAQ_URL;

    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function GetContent(kind) {
    const Urls = CONTENT_URL + `${kind}`;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getContact() {
    const Urls = CONTACT_URL;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}
export function getPriceingTable() {
    const Urls = PRICINGTABLE_URL;
    const promise = axios.get(Urls);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
}

export function addreservation2(
    name,
    email,
    phone,
    whattype,
    about,
    reason,
    state,
    date
) {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("whattype", whattype);
    formData.append("about", about);
    formData.append("reason", reason);
    formData.append("state", state);
    formData.append("date", date);

    const config = {
        headers: {
            "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
        },
    };
    
    const promise = axios.post(RESERVATION_URL, formData);
    // const promise = axios.post({ RESERVATION_URL }, formData, config);
    const dataPromise = promise.then((response) => response);

    return dataPromise;
}

export function funregister(name, lastname, email, tele, username, password) {
    var formData = new FormData();
    formData.append("Name", name);
    formData.append("Lastname", lastname);
    formData.append("Email", email);
    formData.append("Tele", tele);
    formData.append("Username", username);
    formData.append("Password", password);

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
    };

    const promise = axios.post(REGISTER_URL, formData, config);
    const dataPromise = promise.then((response) => response);

    return dataPromise;
}
export function funregister2(name, lastname, email, tele, username, password) {
    var formData = new FormData();
    formData.append("Name", name);
    formData.append("Lastname", lastname);
    formData.append("Email", email);
    formData.append("Tele", tele);
    formData.append("Username", username);
    formData.append("Password", password);

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
    };
    const promise = axios.post(REGISTER_URL, formData, config);
    const dataPromise = promise.then((response) => response);

    return dataPromise;
}
export function funlogin1(username, password) {
    var formData = new FormData();

    formData.append("Username", username);
    formData.append("Password", password);

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, X-Requested-With",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            origin: "*",
        },
    };

    const promise = axios.post(LOGIN_URL1, formData, config);
    const dataPromise = promise.then((response) => response);
     
    return dataPromise;
}
export function funlogin(username, password) {
    var formData = new FormData();

    formData.append("Username", username);
    formData.append("Password", password);

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers": "*",
        },
    };

     
    const promise = axios.post(LOGIN_URL, formData, config);

    const dataPromise = promise.then((response) => response);

    return dataPromise;
}
export function funforget(email) {
    var formData = new FormData();

    formData.append("Email", email);

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
    };

    const promise = axios.post(FORGET_URL, formData, config);
    const dataPromise = promise.then((response) => response);

    return dataPromise;
}