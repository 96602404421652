import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";

import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeaturelogo from "components/features/TwoColWithLogo.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addreservation2 } from "../Apis/apisall";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { redirect, Navigate } from "react-router-dom";
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm text-black`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const userSchema = yup.object().shape({
    // name can not be an empty string so we will use the required function
    name: yup.string().required(),
    email: yup.string().required().email("Invalid Email Address"),
    phone: yup.string().required(),
    whattype: yup.string().required(),
});
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-yellow-900  text-gray-100 w-full py-4 rounded-lg hover:bg-green-1000  flex items-center justify-center `}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
  .bg {
    backgroundcolor: red;
  }
`;
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  button {
    ${tw`bg-white`}
  }
  input,
  select,
  textarea {
    ${tw`w-full text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};
  }
  select {
    ${tw`w-full  text-black`};
  }
  .MuiStack-root {
    ${tw`w-full h-12 border-black text-black`}
    ::placeholder {
      ${tw`text-black`}
    }
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    ${tw`w-full h-12  border-white text-white`}
    ::placeholder {
      ${tw`text-black`}
    }
  }

  .TwoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .Column {
    width: 100%;
  }
  .InputContainer {
    position: relative;
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000;
  }
  .fv-help-block {
    color: red;
  }
`;
const Subheading = tw.span`uppercase tracking-widest font-bold to-black`;
export default () => {
  const notify = () => toast("Wow so easy!");

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whattype, setWhattype] = useState("");
  const [date, setDate] = useState("");
  const [selectedstate, setSelectedstate] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      whattype: "",
      about: "",
      reason: "",
    },
    validationSchema: userSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      setTimeout(() => {
        if (selectedstate === "") {
          setLoading(false);
          setSubmitting(false);
          toast.error("Please Select Time Zone", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          if (date === "") {
            setLoading(false);
            setSubmitting(false);
            toast.error("Please Select Date", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            addreservation2(
              values.name,
              values.email,
              values.phone,
              values.whattype,
              values.about,
              values.reason,
              selectedstate,
              date
            )
              .then((response) => {
                setLoading(false);
                 
                toast.success("FORM SUCCESS", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setTimeout
                (() => {
                  window.location.href = "/Submitted"
                
                }, 2000);
              })
              .catch((error) => {
                 
                setLoading(false);
                toast.danger("Form Not submitted", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              });
          }
        }
      }, 1000);
    },
  });
  function Selectednationality(event) {
    setSelectedstate(event);
  }
  function Selecteddate(event) {
    setDate(event.$d);
  }
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2 style={{ color: "#000", marginBottom: 50 , display:'flex' , justifyContent:'center' }}>
              Book a Consultation
            </h2>
            <h4 style={{ color: "#000", marginBottom: 50 , display:'flex' , justifyContent:'center',textAlign:'center' }}>
            To secure your selected plan and discuss payment options, or if you have any questions and need more information, simply fill out our quick and easy 'Book a Consultation' form. A specialist will contact you shortly to confirm your details and finalize your enrollment.
            </h4>
            <form onSubmit={formik.handleSubmit} noValidate className="form">
              <div className="TwoColumn">
                <div className="Column">
                  <div className="InputContainer">
                    <Label htmlFor="name-input">Name </Label>
                    <Input
                      id="txtfirstname"
                      {...formik.getFieldProps("name")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.name && formik.errors.name,
                        },
                        {
                          "is-valid":
                            formik.touched.name && !formik.errors.name,
                        }
                      )}
                    />
                    <div className="fv-row">
                      {formik.touched.name && formik.errors.name && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="InputContainer">
                    <Label htmlFor="email-input">Email</Label>
                    <Input
                      type="email"
                      id="txtemail"
                      {...formik.getFieldProps("email")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.email && formik.errors.email,
                        },
                        {
                          "is-valid":
                            formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />

                    <div className="fv-row">
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="InputContainer ">
                    <Label>Time Zone</Label>
                    <select
                      style={{ color: "#000" }}
                      onChange={(e) => Selectednationality(e.target.value)}
                    >
                      <option value="0"></option>
                      <option value="EST (Eastern)">EST (Eastern)</option>
                      <option value="CST (Central)">CST (Central)</option>
                      <option value="MST (Mountain)">MST (Mountain)</option>
                      <option value="PST (pacific)">PST (pacific)</option>
                    </select>
                  </div>
                  <div className="InputContainer">
                    <Label htmlFor="email-input">Reservtion Date&Time</Label>
                    <LocalizationProvider
                      style={{ color: "#000" }}
                      dateAdapter={AdapterDayjs}
                    >
                      <DemoContainer
                        style={{ color: "#000" }}
                        components={["DateTimePicker"]}
                      >
                        <DesktopDateTimePicker
                          style={{ color: "#000" }}
                          onChange={(e) => Selecteddate(e)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className="InputContainer">
                    <Label htmlFor="name-input">
                      Where did you hear about us?
                    </Label>

                    <textarea
                      style={{ background: "#rgb(237 242 247)", color: "#000" }}
                      id="txtabout"
                      rows={4}
                      {...formik.getFieldProps("about")}
                    />
                  </div>
                </div>
                <div className="Column">
                  <div className="InputContainer">
                    <Label htmlFor="email-input">Phone</Label>
                    <Input
                      id="txtPhone"
                      {...formik.getFieldProps("phone")}
                      onKeyPress={(event) => {
                        if (!/^[0-9 -+]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.phone && formik.errors.phone,
                        },
                        {
                          "is-valid":
                            formik.touched.phone && !formik.errors.phone,
                        }
                      )}
                    />

                    <div className="fv-row">
                      {formik.touched.phone && formik.errors.phone && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.phone}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
          
                  <div className="InputContainer">
                    <Label htmlFor="email-input">
                      What type of service are you looking for?
                    </Label>
                    <Input
                      id="txtJob"
                      {...formik.getFieldProps("whattype")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.whattype && formik.errors.whattype,
                        },
                        {
                          "is-valid":
                            formik.touched.whattype && !formik.errors.whattype,
                        }
                      )}
                    />

                    <div className="fv-row">
                      {formik.touched.whattype && formik.errors.whattype && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.whattype}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="InputContainer">
                    <Label htmlFor="name-input">
                      Describe anything you think is necessary to explain
                    </Label>
                    <textarea
                      style={{ background: "#rgb(237 242 247)", color: "#000" }}
                      id="txtDescribe"
                      {...formik.getFieldProps("reason")}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <SubmitButton
                style={{ backgroundColor: "#3e914d" }}
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <>
                    <LoginIcon className="icon" />
                    <span className="text">Submit Form</span>
                  </>
                )}
                {loading && (
                  <>
                    <i class="fa fa-spinner fa-spin"></i>
                    Please wait
                  </>
                )}
              </SubmitButton>

              <ToastContainer />
            </form>
          </div>
        </FormContainer>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};