import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

import tw from "twin.macro";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import Header from "../components/headers/light.js";
import Slider from "react-slick";
import Footer from "components/footers/FiveColumnWithBackground.js";
import "react-image-gallery/styles/css/image-gallery.css";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import {
  getPriceing,
  getServicesByImages,
  getPriceingTable,
} from "../Apis/apisall";

import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Testimonials = styled.div``;

const Contents = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Heading = tw.h1`text-gray-1001 text-3xl md:text-5xl leading-snug max-w-3xl`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-green-1000 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;

const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw` uppercase mb-5 `}
`;

const TestimonialTextSlider = tw(Slider)``;

const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const HeadingTitle = tw(
  SectionHeading
)`lg:text-left text-gray-1001 leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-1001`;
const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 `}
    `}
`;
const highlightGradientsCss = [
  css`
    background: rgb(56, 178, 172);
    background: rgb(62 145 77 / var(--tw-bg-opacity));
  `,
];
const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
export default () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const [content, setContent] = useState("");
  const [images, setImages] = useState([{}]);
  const [pricing, setPricing] = useState([{}]);
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);
  const galleryimages = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const BuyNowButton = styled(PrimaryButtonBase)`
    ${tw`rounded uppercase text-sm transform `}
  `;
  useEffect(() => {
    setTimeout(() => {
      getPriceing()
        .then((responses) => {
          setContent(responses.data);

          getPriceingTable()
            .then((responsesp) => {
              setPricing(responsesp.data);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }, 1000);
  }, []);

  function Booking(files) {
    window.location.href = "https://virtuaidhub.com/Booking";
  }

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <Contents>
          <TestimonialsContainer>
            <Testimonials>
              <Testimonial>
                <TextContainer textOnLeft={"textOnLeft"}>
                  <HeadingInfo
                    tw="hidden lg:block"
                    subheading={"subheading"}
                    heading={content.title}
                    description={content.subdes}
                  />
                  <TestimonialTextSlider
                    arrows={false}
                    ref={setTextSliderRef}
                    asNavFor={imageSliderRef}
                    fade={true}
                  ></TestimonialTextSlider>
                  {/* <Actions>
                    {content.video != "" ? (
                      <WatchVideoButton onClick={toggleModal}>
                        <span className="playIconContainer">
                          <PlayIcon className="playIcon" />
                        </span>
                        <span className="playText">Watch Video</span>
                      </WatchVideoButton>
                    ) : null}
                  </Actions> */}
                </TextContainer>
              </Testimonial>
            </Testimonials>
          </TestimonialsContainer>
          <Description>
            <div
              dangerouslySetInnerHTML={{ __html: content.fullDescription }}
            />
          </Description>
          <HeaderContainer>
            <Heading>Pricing Plans</Heading>
          </HeaderContainer>
          <PlansContainer>
            {pricing &&
              pricing.map((plan, index) => (
                <Plan key={index} featured={plan.featured}>
                  <div
                    className="planHighlight"
                    css={highlightGradientsCss[0]}
                  />

                  <PlanHeader>
                    <span className="name">{plan.title}</span>
                    <span className="price">{plan.price}</span>
                    <span className="duration">{plan.unit}</span>
                  </PlanHeader>
                  <PlanFeatures>
                    {plan.items &&
                      plan.items.map((feature, index) => (
                        <li className="feature" key={index}>
                          <CheckboxIcon className="icon" />
                          <span className="text">{feature.title}</span>
                        </li>
                      ))}
                  </PlanFeatures>

                  <BuyNowButton
                    onClick={() => Booking()}
                    css={highlightGradientsCss[0]}
                  >
                    Buy Now
                  </BuyNowButton>
                </Plan>
              ))}
          </PlansContainer>
        </Contents>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
