import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
    Container,
    ContentWithPaddingXl,
    ContentWithVerticalPadding,
} from "components/misc/Layouts";

import tw from "twin.macro";
import styled from "styled-components";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeatureDes from "components/features/TwoColWithButtonDes.js";

import Header from "../components/headers/light.js";
import Slider from "react-slick";
import Footer from "components/footers/FiveColumnWithBackground.js";
import "react-image-gallery/styles/css/image-gallery.css";
import { Subheading as SubheadingBase } from "components/misc/Headings";
import {
    getServicesByTitle,
    getContact,
    getmultiinfoservices,
} from "../Apis/apisall";

import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../helpers/ResponsiveVideoEmbed.js";
import { useParams } from "react-router-dom";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import BigLogos from "../images/logo_white_background.jpg";

import DesignIllustration from "../images/design-illustration.svg";
const Testimonials = styled.div ``;
const SectionContainer = tw(ContentWithVerticalPadding)
``;

const SectionDescription = tw(
    DescriptionBase
)
`text-center mx-auto text-gray-600 max-w-4xl`;
const TwoColumn = tw.div `flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div `relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div `relative mt-12 lg:mt-0 flex flex-col justify-center`;
const Contents = tw.div `max-w-screen-xl mx-auto py-10 lg:py-24`;
const Heading = tw.h1 `font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p `my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const Subheading = tw(SubheadingBase)
`mb-4`;
const Actions = tw.div `flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const SectionHeading = tw(HeadingBase)
`text-black text-2xl mb-10`;
const SectionImgHeading = tw(
    HeadingBase
)
`flex justify-center mb-10 text-secondary-1000`;
const PrimaryButton = tw.button `font-bold px-8 lg:px-10 py-3 rounded bg-green-1000 text-gray-100 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button `
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-green-1000 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000
`;
const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-1000 text-gray-100
  max-w-xs
  border-b-0
  font-bold
  text-center
  mt-5
`;
const PrimaryLink2 = tw(NavLink)`
  lg:mx-0
  px-8 py-4 rounded bg-green-1000 text-gray-100
  max-w-xs
  border-b-0
  font-bold
  text-center
  mt-5
  w-full
 
  flex
  justify-center
  text-xl
`;
const Content = tw.div`max-w-screen-xl mx-auto lg:py-24`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const TextContainer = styled.div((props) => [
  tw`flex flex-col justify-around w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl`;
const QuestionToggleIcon = tw`ml-2 transition duration-300`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;
const TestimonialsContainer = tw.div`mt-16 h-3/4 lg:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;
const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;
const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
]);
const ImageHeading = tw(HeadingBase)`flex justify-center text-primary-900`;
const HeadingTitle = tw(
  SectionHeading
)`lg:text-left text-gray-1001 leading-tight mb-16 `;
const HeadingTitle2 = tw(
  SectionHeading
)`lg:text-center text-6xl text-red-900 mt-20 leading-tight mb-16 `;
const HeadingTitle3 = tw(
  SectionHeading
)`lg:text-center text-2xl text-gray-1001 leading-tight mb-16 `;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0  lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-semibold mt-4 text-red-1000`;
const FormContainer = styled.div`
  ${tw`sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  button {
    ${tw`bg-white`}
  }
  input,
  select,
  textarea {
    ${tw`w-full text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};
  }
  select {
    ${tw`w-full  text-white`};
  }
  .MuiStack-root {
    ${tw`w-full h-12 border-white text-white`}
    ::placeholder {
      ${tw`text-white`}
    }
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    ${tw`w-full h-12  border-white text-white`}
    ::placeholder {
      ${tw`text-white`}
    }
  }
  .TwoColumn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Column {
    width: 100%;
  }
  .InputContainer {
    position: relative;
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    direction: rtl;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .fv-help-block {
    color: red;
  }

  .SubmitButtons {
    border-radius: 9999px;
    border-bottom-width: 0;
    --tw-bg-opacity: 1;
    color: rgb(247 250 252 / var(--tw-text-opacity));
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    --tw-text-opacity: 1;
    background-color: rgb(62 145 77 / var(--tw-bg-opacity));
    width: 160px;
  }
`;
export default ({
  description = "Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  primaryButtonText = "Training Service",

  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://api.virtuaidhub.com/Dynamic/trailer_hd.mp4",
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const [content, setContent] = useState("");
  const [info, setInfo] = useState([{}]);
  const [contact, setContact] = useState("");

  const params = useParams();
  let title = params.name;
  let address = params.name.replace(/ /g, "-");

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      getServicesByTitle(title)
      .then((responses) => {
        setContent(responses.data);

          getmultiinfoservices(responses.data.serviceId)
            .then((responsesslide) => {
              setInfo(responsesslide.data);
            })
            .catch((error) => {});

          getContact(4)
            .then((responses) => {
              setContact(responses.data);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }, 1000);
  }, []);
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <Content>
          <SectionImgHeading>{content.title}</SectionImgHeading>
          <SectionHeading>{content.titleIntro}</SectionHeading>
          <TestimonialsContainer>
            <Testimonials>
              <Testimonial>
                {content.video != "" ? (
                  <ResponsiveVideoEmbed url={content.video} tw="w-full" />
                ) : null}
                <TextContainer textOnLeft={"textOnLeft"}>
                  <HeadingInfo
                    tw="hidden lg:block"
                    subheading={"subheading"}
                    heading={""}
                    description={content.titleVideo}
                  />

                  {/* <PrimaryLink href={"/Training/" + address}>
                    {primaryButtonText}
                  </PrimaryLink> */}
                </TextContainer>
              </Testimonial>
            </Testimonials>
          </TestimonialsContainer>
          <TestimonialsContainer>
            {info &&
              info.map((item, index) => (
                <MainFeatureDes
                  subheading={<Subheading>{item.title}</Subheading>}
                  imageSrc={item.images}
                  imageBorder={true}
                  imageDecoratorBlob={true}
                  description={item.subTitle}
                  address={
                    item.title != null ? item.title.replace(/ /g, "-") : ""
                  }
                />
              ))}
          </TestimonialsContainer>
          <FormContainer>
            <div className="TwoColumn">
              {/* <div className="Column"> */}
                {/* <div className="InputContainer"> */}
                  {/* {" "} */}
                  {/* <PrimaryLink2 href={"/Training/" + address}>
                    {primaryButtonText}
                  </PrimaryLink2> */}
                {/* </div> */}
              {/* </div> */}
              <div className="Column">
                <div className="InputContainer">
                  {" "}
                  <PrimaryLink2 href={"/Booking/"}>
                    Book a Consultation
                  </PrimaryLink2>
                </div>
              </div>
            </div>
          </FormContainer>
          <HeadingTitle2>Let’s Work Together</HeadingTitle2>
          <HeadingTitle3>
            “Unlock potential, embrace flexibility, achieve greatness ”
          </HeadingTitle3>
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    <Description>{description}</Description>
  </div>
);