export const menuItems = [
  {
    title: "Services",
    submenu: [
      {
        title: "General Virtual Assistant",
      },
      {
        title: "Administrative Virtual Assistant",
      },
      {
        title: "Real State Virtual Assistant",
      },
      {
        title: "Call Center",
      },
      {
        title: "Others",
      },
    ],
  },
];
