/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from "react";

export default ({ url, background = "black", className = "video" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",

        paddingTop: 25,
        height: 380,
      }}
    >
      <video key={url} width="750" height="300" autoPlay muted controls>
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};
