import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { HashLink } from "react-router-hash-link";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const PrimaryLink = tw(HashLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-1000 text-gray-100
  
  border-b-0
  rounded-full
`;
const Heading = tw(
  SectionHeading
)`mt-4 text-secondary-1000 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-1000`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
  address = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <img
            style={{ width: 400 }}
            src={"https://admin.virtuaidhub.com/DynamicImages/logo_white.png"}
            alt=""
          />
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{subheading}</Description>
            <Actions>
              <PrimaryLink smooth to={"/Services/" + address}>
                More Info Service
              </PrimaryLink>
            </Actions>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
