import React, { useEffect, useState, useRef } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Slider.js";
import Features from "components/features/Services.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeaturelogo from "components/features/TwoColWithLogo.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";

import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";

import { getServices, getFaqs } from "./Apis/apisall";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold to-black`;
  const videoRef = useRef();

  const [service, setService] = useState([]);
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      getServices()
        .then((responses) => {
           
          setService(responses.data);
           
        })
        .catch((error) => {});

      getFaqs()
        .then((responsefaq) => {
           
          setFaq(responsefaq.data);
        })
        .catch((error) => {});
    }, 1000);
  }, []);
   
  return (
    <AnimationRevealPage>
      <section id="home">
        <Hero id="Home" roundedHeaderButton={true} />
      </section>
      <video 
        src="https://assets.polestar.com/video/test/polestar-1_09.mp4"
        type="video/mp4"
      />
      {service && (
        <>
          {service.map((item, index) => (
            <>
              {index == 0 ? (
                <>
                  <section id="services1"></section>
                  <MainFeature
                    subheading={<Subheading>{item.title}</Subheading>}
                    imageSrc={item.logo}
                    imageBorder={true}
                    imageDecoratorBlob={true}
                    description={item.description}
                    address={item.title.replace(/ /g, "-")}
                  />
                </>
              ) : index == 1 ? (
                <>
                  <section id="services2"></section>
                  <section id="Feature">
                    <FeatureWithSteps
                      heading={<>{item.title}</>}
                      subheading={item.description}
                      textOnLeft={false}
                      imageSrc={item.logo}
                      imageDecoratorBlob={true}
                      decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
                      address={item.title.replace(/ /g, "-")}
                    />
                  </section>
                </>
              ) : index == 2 ? (
                <>
                  <section id="services3"></section>
                  <MainFeature2
                    subheading={<Subheading>VALUES</Subheading>}
                    heading={item.title}
                    imageSrc={item.logo}
                    showDecoratorBlob={false}
                    description={item.description}
                    address={item.title.replace(/ /g, "-")}
                  />
                </>
              ) : index == 3 ? (
                <>
                  <section id="services4"></section>
                  <section id="Feature">
                    <FeatureWithSteps
                      heading={<>{item.title}</>}
                      subheading={item.description}
                      textOnLeft={false}
                      imageSrc={item.logo}
                      imageDecoratorBlob={true}
                      decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
                      address={item.title.replace(/ /g, "-")}
                    />
                  </section>
                </>
              ) : index == 4 ? (
                <>
                  {" "}
                  <section id="services5"></section>
                  <MainFeature2
                    subheading={<Subheading>VALUES</Subheading>}
                    heading={item.title}
                    imageSrc={item.logo}
                    showDecoratorBlob={false}
                    description={item.description}
                    address={item.title.replace(/ /g, "-")}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ))}
        </>
      )}

      <section id="faq"></section>
      <MainFeaturelogo
        subheading={<Subheading></Subheading>}
        heading={""}
        imageSrc={""}
        showDecoratorBlob={false}
        description={""}
      />
      {faq && <FAQ heading={<>FAQ</>} faqs={faq} />}
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
