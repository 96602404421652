import { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import Dropdown from "./Dropdown";
import { HashLink } from "react-router-hash-link";
import { swap } from "formik";
export const PrimaryLink1 = tw(HashLink)`
text-lg lg:text-sm  lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-red-1000 hocus:text-red-1000 flex-100
`;
const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <PrimaryLink1
          smooth
          to={
            items.title == "General Virtual Assistant"
              ? "/#services1"
              : items.title == "Administrative Virtual Assistant"
              ? "/#services2"
              : items.title == "Real State Virtual Assistant"
              ? "/#services3"
              : items.title == "Call Center"
              ? "/#services4"
              : "/#services5"
          }
        >
          {items.title}
        </PrimaryLink1>
      )}
    </li>
  );
};

export default MenuItems;
